.mb-search {
  padding: 20px;
  .load-more-cnt {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
  
  .catalog-title {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .mb-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    gap: 20px;
  }
  
  .mb-item {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mb-item-image {
    position: relative; 
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .mb-item-image-element {
    max-width: 100%;
    height: auto;
  }
  
  .mb-item-title {
    padding-top: 5%;
    text-align: center;
  }
  
  .mb-item-price {
    font-weight: bold;
    text-align: center;
  }
  
  .mb-item-button {
    text-align: center;
    width: 80%;
  }
  
  .mb-item-button button {
    background-color: white;
    color: black;
    padding: 3%;
    border: 1px solid #000000;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 100%;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  
  .rs-carousel {
    height: fit-content;
  }
  
  .mb-item-button button:hover {
    background-color: black;
    color: white;
  }
  
  
  .heart-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    transition: color 0.3s ease;
    color: white;
  }
  
  .heart-button:hover {
    color: red;
  }
  
  .heart-button.filled {
    color: red;
  }
  
  .mb-filters {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    .rs-picker {
      margin-right: 20px;
      max-width: 300px;
    }
  }
  
  .offer-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: red;
    color: white;
    width: 30%;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
  }
  