.mb-header {
  background: var(--color1);
  .rs-navbar.rs-navbar-default {
    background: var(--color1);
  }
  .rs-nav-horizontal {
    display: flex;
    letter-spacing: -1px;
  }
  a {
    padding: 0 20px;
    font-size: 20px;
    line-height: 56px;
    text-decoration: none;
    height: 56px;
    display: block;
    color: var(--w);
    i {
      margin-right: 5px;
    }
  }
  a:link, a:visited, a:active {
    text-decoration: none;
    color: var(--w);
  }
  a:hover {
    background: var(--color2);
    color: var(--w);
  }

  .mb-account-link {
    display: flex;
    .rs-avatar {
      margin-top: 9px;
      margin-bottom: -30px;
      margin-left: 10px;
    }
  }
  .rs-badge-content {
    top: 17px;
    right: -7px;
  }
  .rs-navbar-nav {
    a {
      svg {
        margin: -3px 0 0 5px;
      }
    }
  }
}

.mb-header-menu-mobile {
  width: 240px;
  position: absolute;
  z-index: 100;
  height: 100vh;
  background-color: var(--rs-sidenav-default-bg);
  top: 0;
  transition: left 0.5s;
  .openclosebtn {
    margin-bottom: -35px;
    position: relative;
    margin-left: 240px;
    width: 35px;
    height: 35px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 25px;
      height: 25px;
      path {
        fill: #ffffff;
      }
    }
  }
}

.mb-header-mobile {
  height: 35px;
  width: 100%;
  background: var(--color1);
  text-align: center;
  line-height: 35px;
  font-family: 'Lato';
  font-size: 20px;
}

.openclosebg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
}

@media only screen and (max-device-width: 1000px) {
  .mb-header {
    a {
      padding: 0 10px;
      font-size: 0.9rem;
    }
    .rs-navbar-brand {
      padding: 0;
    }
  }
}
