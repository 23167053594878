
.mb-footer {
  background-color: #333; 
  color: #fff; 
  padding: 20px 0; 
  text-align: center; 
  bottom: 0;
  width: 100%;
}

.footer-links {
  display: flex;
  justify-content: space-between; 
  width: 100%; 
  margin: 0 auto; 
}

.sales-links, .social-links {
  flex: 1; 
}

.sales-links h4, .social-links h4 {
  font-size: 1.2rem; 
  margin-bottom: 10px; 
}

.sales-links ul, .social-links ul {
  list-style-type: none; 
  padding: 0; 
}

.sales-links ul li, .social-links ul li {
  margin-bottom: 8px; 
}

.sales-links ul li a, .social-links ul li a {
  color: #fff; 
  text-decoration: none;
}

.sales-links ul li a:hover, .social-links ul li a:hover {
  text-decoration: underline; 
}  