/* Product.css */

.mb-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
  color: black;
}

.mb-product-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.mb-product-images {
  display: flex;
  gap: 20px;
  align-items: center;
}

.thumbnail-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.thumbnail.selected {
  border-color: #000;
}

.main-image-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.main-image {
  width: 80%;
  height: auto;
  object-fit: cover;
}

.mb-product-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mb-product-info span {
  font-size: 16px;
}

.mb-product-info strong {
  font-weight: bold;
}

.tags-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .tag-clickable {
    cursor: pointer;
  }
}

.tag {
  background-color: #000; 
  color: white; 
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}
.mb-comments-section {
  width: 100%;
  margin-top: 40px;
  padding: 20px;
  border-top: 1px solid #ccc;
  background-color: #f9f9f9;
}

.mb-comments-section h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.mb-comments {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.comment {
  display: flex;
  align-items: flex-start; 
  justify-content: space-between; 
  margin-bottom: 10px;
  position: relative;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
}

.comment-avatar {
  margin-right: 10px;
}

.comment-user {
  font-weight: bold;
  margin-bottom: 5px;
}
.comment-content {
  display: flex;
  flex-direction: column;
  flex: 1; 
  position: relative; 
}

.comment-actions {
  position: absolute;
  top: 0;
  right: 0;
}

.comment-actions .fas.fa-ellipsis-v {
  cursor: pointer;
  
  font-size: 16px;
}
.comment-text {
  color: #555;
}


.comments-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.rating-input{
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: left ;
}
.star-rating{
  width: 90%;
}
.save-btn {
  background-color: black;
  color: white;
  border: 1px solid #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 100%;
  transition-duration: 0.4s;
  cursor: pointer;
  margin-left: 1%;
}

.btn-edit {
  max-width: 200px;
  margin-bottom: 10px;
}

.fav-button {
  background-color: white;
  color: black;
  border: 1px solid #000000;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 100%;
  transition-duration: 0.4s;
  cursor: pointer;
}

.fav-button.filled {
  background-color: red;
  color: white;
}
.fav-button:hover {
  background-color: red;
  color: white;
}

.product-header {
  display: flex;
  align-items: center;
  padding-bottom: 1%;
}

.product-header h1 {
  flex-grow: 1;
}
.quantity-cart-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2%;
}

.quantity-cart-container span {
  margin-right: 10px;
  color: #000;
}

.quantity-cart-container select {
  width: 60px;
  margin-right: 10px;
  padding: 1%;
  border-radius: 5px;
  border: 1px solid #000000;
}

.quantity-cart-container button {
  background-color: white;
  color: black;
  border: 1px solid #000000;
  text-align: center;
  text-decoration: none;
  font-size: 100%;
  transition-duration: 0.4s;
  cursor: pointer;
}
.quantity-cart-container button:hover {
  background-color: black;
  color: white;
}
.rating-input{
  display: flex;
}

.offers-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.offers-section h3 {
  margin-bottom: 5px;
  font-size: 100%;
  color: #333;
}
.precioSpan{
  border: 1px solid #ddd;
  align-items: end;
  padding: 1%;
  background-color: black;
  color: white;
  border-radius: 8px;
}

.offer {
  background-color: #fff;
  padding: 1%;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.offer span {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #555;
}

.offer .offer-description {
  margin-top: 5px;
  font-size: 14px;
  color: #777;
  font-style: italic;
}

.mb-product-recommendations {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
