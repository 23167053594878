@import '~rsuite/dist/rsuite.min.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto+Condensed:wght@300;400;700&display=swap');

:root {
  --color1: #000000;
  --color2: #F7CACD;
  --color3: #F29CA3;
  --w: #ffffff;
  --b: #0B090A;
  --PhoneInput-color--focus: #660708 !important;
  --rs-btn-primary-bg: #F7CACD;
}

.rs-theme-dark {
  --rs-body: #84E6F8;
  --rs-carousel-bg: #84E6F8;
}

body {
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.textcolor1 {
  color: #B1A7A6;
}

.textcolor2 {
  color: #D3D3D3;
}

.textcolor3 {
  color: #3a0ca3;
}

.textcolor4 {
  color: #F5F3F4;
}

.textcolor5 {
  color: #4cc9f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mainscroll {
  overflow: scroll;
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
  overflow-x: hidden;

  .rs-btn-ghost {
    color: var(--color2);
    border-color: var(--color2);
  }

  .rs-carousel-label-wrapper input:checked ~ label {
    background: var(--color1);
  }

  .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
  .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
    border-color: var(--color1);
  }

  .rs-input:focus,
  .rs-input:hover {
    border-color: var(--color1);
  }

  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    border-color: var(--color1);
  }

  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: var(--color1);
    background-color: var(--color1);
  }

  .rs-checkbox-wrapper::before {
    border: 1px solid var(--color1);
  }
}

@base-color: var(--color1);

button {
  background: transparent;
  padding: 0;
  outline: 0;

  &:hover {
    box-shadow: none !important;
  }
}

.mb-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-loader-wrapper,
.mb-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: #f1f1f1;

  .rs-progress-circle {
    width: 80px;
    position: fixed;
    font-family: 'Lato';
  }
}
div.dropzone {
  border: 1px dashed #a1313f;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-device-width: 1000px) {
  .mainscroll {
    overflow: scroll;
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
    width: 100%;
  }
}
