.mb-enter {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 100px auto;
  justify-content: center;
  position: relative;
  .mb-signin {
    text-align: center;
    
  }
  .rs-control-label {
    text-align: left;
  }
  .inactive,
  .active {
    width: 324px;
    margin: 0 20px;
    padding: 2%;
    border-radius: 3%;
    
  }
  .active{
    width: 100%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .inactive{
    background-color: black;
    color: white;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .mb-signup {
    text-align: center;
    .create-desc div {
      margin-top: 10px;
      margin-buttom: 10px;
    }
    
  }
  h4 {
    font-size: 18px;
    font-weight: 400;
  }
  .rs-message {
    width: 300px;
    margin: 0 auto 24px;
  }
  &.login {
    .mb-signin {
      .inactive { display: none; opacity: 0; }
      .active { display: block; opacity: 1; }
    }
    .mb-signup {
      .inactive { display: block; opacity: 1; }
      .active { display: none; opacity: 0; }
    }
  }
  &.logon {
    .mb-signin {
      .inactive { display: block; opacity: 1; }
      .active { display: none; opacity: 0; }
    }
    .mb-signup {
      .inactive { display: none; opacity: 0; }
      .active { display: block; opacity: 1; }
    }
  }
  .mb-mobile-tab {
    display: none;
    margin-bottom: 40px;
  }
  .rs-form-group {
    position: relative;
    label {
      margin-right: 10px;
    }
    .cl-visibility {
      width: 20px;
      display: block;
      position: absolute;
      top: 10px;
      right: 50px;
    }
  }
  .button-center {
    display: flex;
    justify-content: center;
    .rs-btn-primary {
      width: 100px;
    }
  }
}

@media only screen and (max-device-width: 767px) {
  .mb-enter {
    flex-direction: column;
    margin: 20px auto;
    &.logon .mb-signin .inactive,
    &.login .mb-signup .inactive {
      display: none;
    }
    .mb-mobile-tab {
      display: block;
    }
    .active {
      width: 85%;
      margin: 0 auto;
      box-shadow: none;
      .rs-form-group {
        display: flex;
        label {
          line-height: 36px;
          text-align: right;
          margin-right: 5px;
          width: 60px;
        }
        .cl-visibility {
          top: 8px;
          right: 30px;
        }
        .rs-form-control {
          flex: 1;
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
