.home {
}

.cover-carousel {
  .cover-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.product-details {
  padding: 15px;
  text-align: center;
}

.mb-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 20px;
}

.mb-item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.mb-item-image {
  position: relative; 
  width: 100%;
  display: flex;
  justify-content: center;
}

.mb-item-image-element {
  max-width: 100%;
  height: auto;
}

.mb-item-title {
  padding-top: 5%;
  text-align: center;
}

.mb-item-price {
  font-weight: bold;
  text-align: center;
}

.mb-item-button {
  text-align: center;
  width: 80%;
}

.mb-item-button button {
  background-color: white;
  color: black;
  padding: 3%;
  border: 1px solid #000000;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 100%;
  transition-duration: 0.4s;
  cursor: pointer;
}


.rs-carousel {
  height: fit-content;
}

.mb-item-button button:hover {
  background-color: black;
  color: white;
}


.heart-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;
  color: white;
}

.heart-button:hover {
  color: red;
}

.heart-button.filled {
  color: red;
}

.offer-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  width: 30%;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
