.offer-product-section {
    margin-bottom: 15px;
  }
  
  .offer-product-section label {
    display: block;
    margin-bottom: 5px;
  }
  
  .offer-product-section input,
  .offer-product-section .rs-picker {
    width: 100%;
  }